// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// const { $ } = require("@rails/ujs")
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

$(document).ready(function() {

  //
  // Login
  //
  if (window.location.pathname == '/login' || window.location.pathname == '/failure' || window.location.pathname == '/support') {

    $(".login-buttom").click(function() {

      $("#sign-in").submit()
    })
  }

  if (location.search.split("?pw=")[1]) {
    $(".column._3-col").remove()
  }


  //
  // Populate user
  //
  var userId = JSON.parse(document.querySelector("meta[name=user]").attributes.content.value)
  var user = null
  var toDoCount = null

  $.ajax("/admin/users/" + userId + ".json", {
    type: "GET",
    headers: { 'Content-Type': 'application/json' }
  })
  .done(function(data, status) {
    $(".body-container").css({
      "visibility": "visible",
      "opacity": 1
    })

    user = data
    // console.log(user)
    toDoCount = user.location != "london" ? 3 : 2;

    if (!user.id && window.location.pathname == "/") {
      window.location = "/login"
    }

    if (!user.id) {
      return false
    }

    //
    // Populate purple card
    //
    $("#user-first_name").text(user.first_name)
    $("#user-last_name").text(user.last_name)
    $("#user-logo").attr("src", user.co_logo)

    let monthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    $("#user-year").html(user.join_date.split("-")[0])
    $("#user-month").html(monthArray[user.join_date.split("-")[1] - 1])

    //
    // Populate zoom windows
    //
    if ($(".home-zoom-slider-wrapper") && user.location != "bay-area") {

      $(".home-zoom-slider-wrapper").load( "/home-zoom-slider #zoom-" + user.location );
    }

    //
    // Manage Job Listing Form
    //
    if ($("#job-board").length) {

      $("#wf-form-job-board .w-radio").click(function() {

        if ($(this).find("input").val() == "company") {

          $(".jobs-option-wrapper").show()
          $(".jobs-submit-wrapper").show()
        } else {

          $(".jobs-option-wrapper").hide()
          $(".jobs-submit-wrapper").hide()
        }
      })

      if (user.co_manager && $("#company").length) {

        $(".jobs-option-wrapper").show()
        $(".jobs-submit-wrapper").show()
        $(".jobs-option-wrapper #job-manager").val(user.co_manager.name)
        $(".jobs-option-wrapper #email-2").val(user.co_manager.email)
        $(".w-radio").hide()
      }
    }

    //
    // Show user location on map
    //
    if ($("#map_canvas").length) {
      
      initMap(user.location)
      $("#" + user.location + "-title").show()
      $("#" + user.location).show()
      $("#name").val(user.first_name + " " + user.last_name)
      $("#email").val(user.email)
      $("#Company").val(user.co)    
    }

    //
    // Show welcome message for new users
    //
    if (!user.checklist_status) {

      $( "#ajax-welcome" ).load( "/welcome #welcome-message" );
    }

    //
    // Team user
    //
    if (user.role == "team") {

      $("body").addClass("team-member")
    }

    //
    // Add Admin link
    //
    if (user.role == "admin") {
      
      $("#logout").before('<a href="/admin/users" class="link-block full-width flex align-center padding-y---xxxs nav-hover w-inline-block"><img src="/images/616628c84536f3ee10b048fb_icon-home.svg" loading="lazy" alt="" class="margin-right---10 margin-top----2"><div class="medium">Admin</div></a>')
    }

    //
    // Handle to dos on home page
    //
    for (const property in user.checklist_status) {

      $("#" + property + "-task").hide()
    }

    // Hide to dos section if to dos are complete
    if (user.checklist_status && Object.keys(user.checklist_status).length >= toDoCount) {
      $("#to-dos").hide()
    }

    //
    // Hide content based on location
    //
    var resources = $(".content-location")

    for (let index = 0; index < resources.length; index++) {
      const resource = resources[index];
      
      if ($(resource).children(".content-" + user.location).text() == "false") {
        $(resource).parent().parent().hide()
      }

      if (user.role == "team" || user.role == "admin") {
        if ($(resource).children(".content-internal").text() == "true") {
          $(resource).parent().parent().show()
        }
      }
    }

    if (user.location == "london") {
      $("#newsletter-task").remove()
    }

    $(".column._3-col.whole-tab").css({
      "visibility": "visible",
      "opacity": 1
    })
  })


  //
  // Handle logout
  //
  $("#logout").on("click", function(e) {
    e.preventDefault()

    $("#logout-form").submit()
  })


  //
  // Handle no on to dos
  //
  $(".task .no-btn").click(function() {

    let taskEl = $(this).closest(".task")
    let task = taskEl.data("task")
    let taskAction = $(this).text() == "No, thanks" ? false : true

    if (!user.checklist_status) {
      user.checklist_status = {}
    }

    user.checklist_status[task] = taskAction

    $.ajax("/admin/do_task/" + userId, {
      type: "PATCH",
      data: JSON.stringify({
        user: {
          checklist_status: user.checklist_status
        }
      }),
      headers: { 'Content-Type': 'application/json' }
    })
    .done(function(data, status) {

      taskEl.hide()

      if (user.checklist_status && Object.keys(user.checklist_status).length >= toDoCount) {
        $("#to-dos").hide()
      }

      user.checklist_status = data.checklist_status
    })
  })


  //
  // Handle forms on to dos and pages
  //
  $(document).on("click", ".form-trigger-button", function(e) {
    e.preventDefault()

    $(this).val("Submitting...")

    let invalid = false
    let inputs = $(this).parent().find(".w-input, .w-select, .w-checkbox-input")
    let formEl = $(this).closest("form")
    let form = {}

    if ($("#Help").val()) {
      
      var block = $("#Help").val()
      form["Help"] = block
      
      inputs = $("#" + block.replace(/\s+/g, '-').toLowerCase() + "-block").find(".w-input, .w-select, .w-checkbox-input")
      if ($("#Notes").val()) {
        inputs = $("#Notes")
      }
    }
    
    $.each(inputs, function (index, input) {

      if ($(input).hasClass("w-checkbox-input")) {

        let field = $(input).next().data("name")
        form[field] = $(input).hasClass("w--redirected-checked") ? "Yes" : ""
      } else {

        if (!$(input).val()) {

          $(input).first().prev(".error").remove()
          $(input).before("<div class='error'>Required</div>")
          invalid = true
        }


        let field = $(input).data("name");
        form[field] = $(input).val();
      }
    });

    form.name = $(formEl).data("name")
    form.user_name = user.first_name + " " + user.last_name
    form.user_email = user.email
    form.user_company = user.co

    if (invalid) {

      $(".text-field, .w-select").on("blur", function() {
    
        $(this).first().prev(".error").remove()
      })
    } else {
      
      $.ajax("/send_form", {
        type: "POST",
        data: JSON.stringify({
          form: form
        }),
        headers: { 'Content-Type': 'application/json' }
      })
      .done(function(data, status) {

        user.checklist_status = data.checklist_status

        if (form.name == "job-board") {

          $(".w-radio").hide()
          $("#wf-form-job-board").prepend("<div class='notice'>Thank you. The Accel team has been notified and will respond shortly.</div>")
          return false
        } else {

          $(formEl).next().show()
          $(formEl).remove()

          $(".success .button").click("click", function() {

            $(this).closest(".slack-modal-background, .discount-modal-background, .events-modal-background").fadeOut()
            $("body").css({"overflow": "auto"})
          })

          for (const property in data.checklist_status) {
            $("#" + property + "-task").hide()
          }

          if (user.checklist_status && Object.keys(user.checklist_status).length >= toDoCount) {
            $("#to-dos").hide()
          }
        }
      })
    }
  })

  //
  // Upload users via CSV
  //
  var inputs = document.querySelectorAll('.file-input')
  var form = document.getElementById('user-form')

  for (var i = 0, len = inputs.length; i < len; i++) {
      customInput(inputs[i])
  }

  function customInput (el) {

    const fileInput = el.querySelector('[type="file"]')
    const label = el.querySelector('[data-js-label]')

    fileInput.onchange = function () {
      if (!fileInput.value) return

      var value = fileInput.value.replace(/^.*[\\\/]/, '')
      el.className += ' -chosen'
      label.innerText = value
      form.submit();
    }
  }
})